function DisclaimerTrigger({ onClick }) {
  return (
    <button
      className={
        'hover:text-link whitespace-nowrap block p-2 dark:hover:text-white dark:hover:underline'
      }
      onClick={onClick}
    >
      Show Disclaimer
    </button>
  );
}

export default DisclaimerTrigger;
