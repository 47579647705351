import { localePrefixes } from '@nrdy-marketing-engine/organic/app/helpers/i18n';

const LINKS = [
  { href: '/privacy', text: 'Privacy' },
  { href: '/guarantee', text: 'Our Guarantee' },
  { href: '/terms-of-use', text: 'Terms of Use' },
  { href: 'https://www.nerdy.com/', text: 'a Nerdy company' },
  { href: '/sitemap', text: 'Sitemap' },
  { href: '/accessibility', text: 'Accessibility' },
  { href: '/login', text: 'Sign In' },
];

function FooterBottomLineLinks({
  intlFooter = false,
  locale,
}: {
  intlFooter?: boolean;
  locale?: localePrefixes;
}) {
  const links = LINKS.map(({ href, text }) => {
    if (
      ['/privacy', '/terms-of-use', '/guarantee'].includes(href) &&
      locale &&
      intlFooter === true
    ) {
      return { href: `${locale}${href}`, text };
    } else {
      return { href, text };
    }
  });

  return (
    <>
      {links.map(({ href, text }) => (
        <a
          className="hover:text-link whitespace-nowrap p-2 block dark:hover:text-white dark:hover:underline"
          href={href}
          key={href}
          rel="noopener noreferrer"
          target="_blank"
        >
          {text}
        </a>
      ))}
    </>
  );
}

export default FooterBottomLineLinks;
