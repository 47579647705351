import { PathProps } from '../@types/types';

const PATH_REGEX = /^(?:\/(?<countryCode>\w{2}(?!\w)))?(?:\/(?<lang>\w{2}(?!\w)))?(?<basePath>.+)$/;

export function extractPathProps(path: string): PathProps {
  const { countryCode, lang, basePath } = path ? path.match(PATH_REGEX).groups : ({} as any);
  const intlPrefix = `${countryCode ? '/' + countryCode : ''}${lang ? '/' + lang : ''}`;
  return {
    countryCode,
    lang,
    basePath,
    intlPrefix,
    path,
  };
}
