function CopyrightNotice() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="text-center">
      <span className="whitespace-nowrap">
        &copy;{currentYear} Varsity Tutors - A Nerdy Company.
      </span>{' '}
      <span className="whitespace-nowrap">All rights reserved.</span>
    </div>
  );
}

export default CopyrightNotice;
