'use client';

import { NSPLocales, localePrefixes } from '@nrdy-marketing-engine/organic/app/helpers/i18n';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import CopyrightNotice from './copyright-notice';
import Disclaimer from './disclaimer';
import DisclaimerTrigger from './disclaimer-trigger';
import FooterBottomLineLinks from './footer-bottom-line-links';
import LocaleDropdown from './locale-dropdown';
interface SimpleFooterProps {
  className?: string;
  currentLocale?: localePrefixes;
  intlFooter?: boolean;
  localeList?: NSPLocales[];
  tag?: keyof JSX.IntrinsicElements;
}

export default function SimpleFooter({
  className,
  tag: Tag = 'footer',
  currentLocale,
  localeList = ['en'],
  intlFooter = false,
}: SimpleFooterProps) {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    let timer;
    if (showDisclaimer) {
      timer = setTimeout(() => {
        window.scrollTo({
          behavior: 'smooth',
          top: document.body.scrollHeight,
        });
      }, 200);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showDisclaimer]);

  return (
    <div className={twMerge('mt-auto py-6 dark:bg-[#153154]', className)}>
      <Tag
        className={twMerge(
          'flex container-xl flex-col justify-center first-letter:bg-light-alt text-dark text-center text-xs dark:bg-[#153154] dark:text-white dark:text-sm md:relative',
          className
        )}
      >
        {intlFooter && (
          <div className="flex justify-center order-none mb-2 lg:mb-0 ">
            <LocaleDropdown localeList={localeList} />
          </div>
        )}

        <CopyrightNotice />

        <nav
          aria-label="Footer links"
          className="flex flex-wrap justify-center leading-3 my-1 space-x-2 pl-[80px] pr-[80px]"
        >
          <DisclaimerTrigger onClick={() => setShowDisclaimer(!showDisclaimer)} />
          <FooterBottomLineLinks intlFooter={intlFooter} locale={currentLocale} />
        </nav>
        <Disclaimer showDisclaimer={showDisclaimer} />
      </Tag>
    </div>
  );
}
