'use client';

import {
  COUNTRY_OPTIONS,
  LANGUAGE_OPTIONS,
  PREFERRED_COOKIES_TO_LOCALE_PREFIXES,
} from '@nrdy-marketing-engine/organic/app/helpers/i18n';
import { extractPathProps } from '@nrdy-marketing-engine/routing/src/extract-path-props';
import Cookies from 'js-cookie';
export class LocaleHelper {
  preferredLocale: string;
  language: string;
  buttonText: string;
  redirectUrl: string;
  countryCode: string;

  public setupValues(countryCode) {
    this.countryCode = countryCode;
    this.preferredLocale = this.countryCode ?? 'US';
    this.language = LANGUAGE_OPTIONS[this.getPreferredLanguages()[0]];
    this.buttonText = this.getButtonString(this.language, this.preferredLocale);
    this.redirectUrl = this.getAlternateUrl(this.preferredLocale);
  }

  public getCountry() {
    return COUNTRY_OPTIONS[this.preferredLocale];
  }

  public showModal() {
    const preferredLangArr = this.getPreferredLanguages();

    if (preferredLangArr && preferredLangArr.length >= 2 && this.getAlternatesList()?.length) {
      const currentUrl = window.location.href;
      let pathName = window.location.pathname?.replace('/nme', '');

      const currentLocale = extractPathProps(pathName).intlPrefix;

      const preferredLocale = PREFERRED_COOKIES_TO_LOCALE_PREFIXES[this.getPreferredLocaleCookie()];

      let alternateString = `${preferredLangArr[0]}-${this.countryCode}`;
      const alternate =
        this.countryCode && this.getAlternate(this.getPreferredLocaleCookie() ?? alternateString);

      if (preferredLocale) {
        if (currentLocale !== preferredLocale) {
          if (!currentLocale) {
            window.location.replace(currentUrl.replace(pathName, `${preferredLocale}${pathName}`));
            return false;
          }
          window.location.replace(currentUrl.replace(currentLocale, preferredLocale));
          return false;
        }
      }

      if (alternate) {
        const locationPath = new URL(location.href).pathname;
        const alternatePath = new URL(alternate?.href).pathname;
        if (locationPath !== alternatePath) return true;
      }
    }

    return false;
  }

  /**
   * Gets the alternate link for a given locale
   * @param {String} locale - The locale to get the alternate link for
   * @return {HTMLLinkElement} The alternate link element
   */
  private getAlternate(locale: string): HTMLLinkElement | undefined {
    if (locale === 'en-US') {
      return document.querySelector('link[rel=alternate][hreflang="x-default"]') as HTMLLinkElement;
    } else {
      return document.querySelector(`link[rel=alternate][hreflang="${locale}"]`) as HTMLLinkElement;
    }
  }
  /**
   * Gets the alternate URL for a given country code
   * @param {String} countryCode - The country code to get the alternate URL for
   * @return {String} The alternate URL
   */
  public getAlternateUrl(countryCode: string) {
    const preferredLangArr = this.getPreferredLanguages();

    const alternate =
      countryCode &&
      (this.getAlternate(`${preferredLangArr[0]}-${countryCode}`) as HTMLLinkElement);

    return alternate?.href ?? '/';
  }

  private getAlternatesList(): NodeListOf<HTMLLinkElement> | undefined {
    return document.querySelectorAll('link[rel=alternate]');
  }

  /**
   * Saves the preferred locale to a cookie
   * @param {String} locale - The locale to save
   */
  public savePreferredLocale(locale) {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    Cookies.set('preferred_locale', locale, { expires: date });
  }

  /**
   * Gets the canonical link
   * @return {String} The canonical link
   */
  public getCanonical() {
    return document.querySelector('link[rel=canonical]').getAttribute('href');
  }

  /**
   * Sets the preferred_locale cookie to the current language.
   */
  public setPreferredLocale(href = location.href.replace(/\/$/, '')) {
    const currentAlternate = document.querySelector(`link[rel=alternate][href="${href}"]`);

    if (currentAlternate) {
      this.savePreferredLocale(currentAlternate.getAttribute('hreflang') || 'en-US');
    }
  }

  /**
   * Generates the button string for a given language code and country code
   * @param {String} langCode - The language code
   * @param {String} countryCode - The country code
   * @return {String} The button string
   */
  public getButtonString(langCode: string, countryCode: string): string {
    const country = COUNTRY_OPTIONS[countryCode];
    return `${langCode} - ${country}`;
  }

  /**
   * Gets the preferred locale cookie
   * @return {String} The preferred locale cookie
   */
  private getPreferredLocaleCookie() {
    return Cookies.get('preferred_locale');
  }

  /**
   * Determines whether or not to show the modal
   * @param {String} preferredLocale - The preferred locale
   * @return {Boolean} Whether or not to show the modal
   */
  private getBrowserLanguage() {
    return window?.navigator?.language || 'en-US';
  }

  /**
   * Gets the preferred languages
   * @return {String[]} The preferred languages
   */
  public getPreferredLanguages(): string[] {
    const cookieValue = this.getPreferredLocaleCookie();
    let preferredLang;

    if (cookieValue) {
      preferredLang = cookieValue;
    } else {
      preferredLang = this.getBrowserLanguage();
    }

    return preferredLang === 'x-default' ? ['en', 'US'] : preferredLang.split('-');
  }
}
