import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import * as styles from './disclaimer-shared.css';

function Disclaimer({ showDisclaimer }) {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    setContentHeight(showDisclaimer ? contentRef.current.scrollHeight : 0);
  }, [showDisclaimer, contentRef]);

  return (
    <div
      className={clsx(
        'space-y-1 text-center container-lg leading-4 prose-xs',
        styles.disclaimer,
        showDisclaimer && styles.disclaimerVisible
      )}
      ref={contentRef}
      style={{ maxHeight: `${contentHeight}px` }}
    >
      <p>
        Names of standardized tests are owned by the trademark holders and are not affiliated with
        Varsity Tutors LLC.
      </p>
      <p>
        Media outlet trademarks are owned by the respective media outlets and are not affiliated
        with Varsity Tutors.
      </p>
      <p>Award-winning claim based on CBS Local and Houston Press awards.</p>
      <p>Varsity Tutors is not affiliated with any universities mentioned on its website.</p>
      <p>
        Varsity Tutors connects learners with a variety of experts and professionals. Tutors,
        instructors, experts, educators, and other professionals on the platform are independent
        contractors, who use their own styles, methods, and materials and create their own lesson
        plans based upon their experience, professional judgment, and the learners with whom they
        engage.
      </p>
      <p>
        1. Lee, J.Y. (2013). “Private tutoring and its impact on students’ academic achievement,
        formal schooling, and educational inequality in Korea.” Unpublished doctoral thesis.
        Columbia University.
      </p>
      <p>
        For press inquiries please contact{' '}
        <a className="underline hover:text-link" href="mailto:press@varsitytutors.com">
          press@varsitytutors.com
        </a>
      </p>
    </div>
  );
}

export default Disclaimer;
