export type localePrefixes = '/' | '/ca' | '/gb';

export type NSPLocales = 'en_CA' | 'en_GB' | 'en';

const NSP_LOCALES_TO_DROPDOWN_OPTIONS = {
  en_CA: 'Canada-English',
  en_GB: 'United Kingdom-English',
  en: 'US-English',
};

const NSP_LOCALES_TO_PREFIXES = {
  en_CA: '/ca',
  en_GB: '/gb',
  en: '/',
};

const PREFIXES_TO_NSP_LOCALES = {
  '/ca': 'en_CA',
  '/gb': 'en_GB',
  '/': 'en',
};

const LOCALE_PREFIXES_TO_COOKIE = {
  '/': 'x-default',
  '/ca': 'en-CA',
  '/gb': 'en-GB',
};

const LOCALE_TO_PHONE_NUMBER = {
  '/ca': '6479522150',
  '/gb': '+442033229665',
};

const INTL_PHONE_NUMBERS = ['+442033229665', '6479522150'];

const PREFERRED_COOKIES_TO_LOCALE_PREFIXES = {
  'x-default': '',
  'en-CA': '/ca',
  'en-GB': '/gb',
};

const LANGUAGE_OPTIONS = {
  en: 'English',
};

const COUNTRY_OPTIONS = {
  CA: 'Canada',
  GB: 'United Kingdom',
  US: 'United States',
};

function parseNSPLocales(localeList: string[]): NSPLocales[] {
  return localeList.filter((locale) =>
    ['en_CA', 'en_GB', 'en'].includes(locale as NSPLocales)
  ) as NSPLocales[];
}

export {
  COUNTRY_OPTIONS,
  INTL_PHONE_NUMBERS,
  LANGUAGE_OPTIONS,
  LOCALE_PREFIXES_TO_COOKIE,
  LOCALE_TO_PHONE_NUMBER,
  NSP_LOCALES_TO_DROPDOWN_OPTIONS,
  NSP_LOCALES_TO_PREFIXES,
  PREFERRED_COOKIES_TO_LOCALE_PREFIXES,
  PREFIXES_TO_NSP_LOCALES,
  parseNSPLocales,
};
