'use client';

import Box from '@mui/material/Box';

import { LocaleHelper } from '@nrdy-marketing-engine/organic/app/components/intl-locale-modal/helpers/client-helpers';
import {
  LOCALE_PREFIXES_TO_COOKIE,
  NSPLocales,
  NSP_LOCALES_TO_DROPDOWN_OPTIONS,
  NSP_LOCALES_TO_PREFIXES,
  localePrefixes,
} from '@nrdy-marketing-engine/organic/app/helpers/i18n';
import { extractPathProps } from '@nrdy-marketing-engine/routing/src/extract-path-props';
import { usePathname } from 'next/navigation';
import { CSSProperties, useEffect, useState } from 'react';

const selectStyles = {
  cursor: 'pointer',
  appearance: 'none',
  border: 'none',
  padding: '0 .125rem 0 1.25rem',
  background: 'none',
  fontSize: '12px',
  boxSizing: 'content-box',
  height: '1.25rem',
  lineHeight: '1.25rem',
  fontWeight: 400,
} as CSSProperties;

function measureText(text, styles) {
  var lDiv = document.createElement('div');

  document.body.appendChild(lDiv);

  lDiv.style.fontFamily = styles.fontFamily;
  lDiv.style.fontSize = styles.fontSize;
  lDiv.style.fontWeight = styles.fontWeight;
  lDiv.style.position = 'absolute';
  lDiv.style.left = '-1000';
  lDiv.style.top = '-1000';

  lDiv.innerHTML = text;

  var lResult = {
    width: lDiv.clientWidth,
    height: lDiv.clientHeight,
  };

  document.body.removeChild(lDiv);
  lDiv = null;

  return lResult;
}

function handleLocaleChange(
  selectedLocale: localePrefixes,
  currentPathname: string,
  nmePrefix: string
) {
  const preferredLocale = LOCALE_PREFIXES_TO_COOKIE[selectedLocale];
  const localeHelper = new LocaleHelper();

  if (preferredLocale) localeHelper.savePreferredLocale(preferredLocale);

  let path = currentPathname.replace(
    extractPathProps(currentPathname).intlPrefix,
    selectedLocale === '/' ? '' : selectedLocale
  );

  window.location.replace(`${nmePrefix}${path}`);
}

function OptionsList({ localeList }: { localeList: NSPLocales[] }) {
  return (
    <>
      {localeList.map((locale) => (
        <option key={locale} value={NSP_LOCALES_TO_PREFIXES[locale]}>
          {NSP_LOCALES_TO_DROPDOWN_OPTIONS[locale]}
        </option>
      ))}
    </>
  );
}

function LocaleDropdown({ localeList = ['en'] }: { localeList?: NSPLocales[] }) {
  const [initialLocale, setInitialLocale] = useState('/');
  const pathname = usePathname();

  const nmePrefix = pathname?.startsWith('/nme') ? '/nme' : '';
  const cleanedPathname = pathname?.replace('/nme', '');

  useEffect(() => {
    const currentLocale = extractPathProps(cleanedPathname).intlPrefix;
    setInitialLocale(currentLocale);
    const picker = document.getElementById('locale-dropdown-select') as HTMLSelectElement;
    if (picker) {
      const text = (picker.selectedOptions[0] && picker.selectedOptions[0].text) || '';
      const styles = window.getComputedStyle(picker);
      const width = measureText(text, styles).width;
      picker.style.width = width + 'px';
    }
  }, [initialLocale]);

  return (
    <>
      <Box
        sx={{
          position: { xl: 'absolute' },
          left: { xl: '0' },
          zIndex: { xl: '1' },
          marginTop: { xl: '1.5rem' },
          textAlign: 'center',
          maxWidth: 'fit-content',
        }}
      >
        <Box sx={{ maxWidth: 'fit-content' }}>
          <Box
            sx={{ width: '1rem', height: '1.25rem', position: 'absolute', pointerEvents: 'none' }}
          >
            <svg
              fill="none"
              height="20"
              viewBox="0 0 20 20"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z"
                fill="currentColor"
              />
            </svg>
          </Box>

          <select
            aria-label="locale dropdown"
            id="locale-dropdown-select"
            onChange={(e) =>
              handleLocaleChange(e.target.value as localePrefixes, cleanedPathname, nmePrefix)
            }
            style={selectStyles}
            value={initialLocale}
          >
            <OptionsList localeList={localeList} />
          </select>
        </Box>
      </Box>
    </>
  );
}

export default LocaleDropdown;
